<template>
  <div>
    <div class="license_en" v-if="lang === 'en'">
      <p @click="lang='ru'" class="license_lang_link">Перейти на русский язык</p>
      <h1>Event Link Terms of Service</h1>
      <p>This document "Terms of Use for Event Link Services" (hereinafter referred to as the Agreement) constitutes an offer
      Limited Liability Company "Event Link" (PSRN 1217800105093, TIN 7816719561),
      hereinafter referred to as Event Link, to the Internet user
      (hereinafter referred to as the User) to conclude an agreement on the use of the "Event Link" service
      (hereinafter the "Service") on the terms set forth below.

    </p><p>1. The use of the Service by the User is governed by this Agreement, as well as

      Privacy Policy posted at: https://evli.in/#/privacy,

      (hereinafter collectively referred to as - Regulatory documents).

      The User undertakes to familiarize himself with all the Regulatory Documents and independently bears the risk of such
      unfamiliarity.

    </p><p>2. To this Agreement and the relationship between Event Link and the User arising from the use of
      Service is subject to the law of the Russian Federation. Throughout the text of this Agreement,
      unless explicitly stated otherwise, the term "legislation" is understood as the legislation of the Russian Federation,
      and the legislation of the place of residence of the User

    </p><p>3. The Service offers the User the ability to create electronic calendars and events in them. Persons,
      agreeing to
      communication with the User using an electronic calendar, they can subscribe to his calendars and events.
      Persons who subscribed to the calendars of the Users are hereinafter referred to as Subscribers.

    </p><p>4. By registering on the Service, the User expresses his unconditional acceptance of all conditions
      of this Agreement and undertakes to comply with them or stop using the Service.

    </p><p>5. When using the Service, the User must comply with the following conditions and restrictions:

    </p><p>do not change / modify in any way the program code of the Service

    </p><p>not use any automatic programs or devices to register on the Service

    </p><p>comply with all requirements of this Agreement and the current legislation on the protection of personal data,
      as well as other information, access to which is limited in accordance with the law,
      and which can be obtained by using the Service.

    </p><p>6. The user is solely responsible for the safety of his login and password from the Service.
      All actions performed on the Service using the username and password belonging to the User
      are considered committed by the User.

    </p><p>7. Service (including information obtained with its help) is provided by Event Link "as is".
      Event Link does not guarantee the compliance of the Service with the goals and expectations of Users, uninterrupted and error-free
      the operation of the Service as a whole, and also does not guarantee the accuracy of the information received using the Service.
      Event Link is not responsible and does not compensate for any damage, direct or indirect, caused to the user
      The Service or to third parties as a result of the use or inability to use the Service,
      as well as as a result of the use or inability to use the information obtained through the Service.

    </p><p>8. The user is solely responsible to third parties for his actions when using
      Of the Service, including guarantees that its actions comply with the requirements of the legislation
      and do not violate the rights and legitimate interests of third parties. The user independently and at his own expense undertakes
      settle all claims of third parties related to the actions of the User when using the Service.

    </p><p>9. The use of the Service by the Subscriber is governed by the Regulatory Documents.

      The User undertakes to familiarize himself with all the Regulatory Documents and independently bears the risk of such
      unfamiliarity.

    </p><p>10. The subscriber bears full personal responsibility for the content of the information provided by him,
      for its compliance with the norms of Russian legislation or legislation
      location of the Subscriber

    </p><p>11. By subscribing to the calendars of the Users, Subscribers expresses their unconditional acceptance of all the conditions
      of this Agreement and undertakes to comply with them or to stop using the Service, and also agrees to
      processing of your personal data.

    </p><p>12. All data collected and stored by the Service, Event Link considers as personal data and
      confidential information of the User or Subscriber. Event Link undertakes, unless otherwise provided by the applicable
      legislation, keep the data secret and not transfer such data to third parties, except in cases where
      when the User or Subscriber has authorized such a transfer or has independently allowed access to data for third parties.

    </p><p>13. Event Link does not guarantee that the use of the service, collection, processing and transmission of Event Link information
      does not violate the law
      location of the User. The user uses the Service at his own risk, and independently bears
      responsibility for all their actions related to the use of the Service.

    </p><p>14. Event Link has the right, without notice, at its sole discretion, to terminate or
      suspend the access of the User or Subscriber to the Service without giving reasons, in particular,
      in case of violation by the User or the Subscriber of the requirements of this Agreement.

    </p><p>15. All claims related to the use / inability to use the Service should be sent to
      to the email address support@evli.in.

    </p><p>16. Event Link may at any time without notice to Users and Subscribers change
      the text of this Agreement and / or
      any other conditions for using the Service. The current text of this Agreement is posted at:
      https://evli.in/#/license.</p>
    </div>
    <div class="license_ru" v-if="lang === 'ru'">
      <p @click="lang='en'" class="license_lang_link">Switch to English</p>

      <h1>Условия использования сервиса Event Link</h1>
      <p>Настоящий документ «Условия использования сервисов Event Link» (далее — Соглашение) представляет собой предложение
      Общества с ограниченной ответственностью «Ивент Линк» (ОГРН 1217800105093, ИНН 7816719561),
      далее именуемого Ивент Линк, пользователю сети Интернет
      (далее — Пользователь) заключить соглашение об использовании сервиса «Ивент Линк»
      (далее "Сервис") на изложенных ниже условиях.

      </p><p>1. Использование Пользователем Сервиса регулируется настоящим Соглашением, а также

      Политикой конфиденциальности, размещенной по адресу: https://evli.in/#/privacy,

      (далее вместе именуются — Регулирующие документы).

      Пользователь обязуется ознакомиться со всеми Регулирующими документами и самостоятельно несет риск такого
      неознакомления.

      </p><p>2. К настоящему Соглашению и отношениям между Ивент Линк и Пользователем, возникающим в связи использованием
      Сервиса, подлежит применению право Российской Федерации. Везде по тексту настоящего Соглашения,
      если явно не указано иное, под термином «законодательство» понимается как законодательство РФ,
      так и законодательство места пребывания Пользователя

      </p><p>3. Сервис предлагает Пользователю возможность создания электронных календарей и событий в них. Лица,
      согласившиеся на
      коммуникацию с Пользователем с помощью электронного календаря, могут подписаться на его календари и события.
      Лица, подписавшиеся на календари Пользователей далее именуются Подписчики.

      </p><p>4. Зарегистрировавшись на Сервисе, Пользователь выражает свое безоговорочное согласие со всеми условиями
      настоящего Соглашения и обязуется их соблюдать или прекратить использование Сервиса.

      </p><p>5. При использовании Сервиса Пользователю необходимо соблюдать следующие условия и ограничения:

      </p><p>не изменять / не модифицировать каким-либо образом программный код Сервиса

      </p><p>не использовать любые автоматические программы или устройства для регистрации на Сервисе

      </p><p>соблюдать все требования настоящего Соглашения и действующего законодательства о защите персональных данных,
      а также иной информации, доступ к которой в соответствии с законодательством ограничен,
      и которая может быть получена при использовании Сервиса.

      </p><p>6. Пользователь самостоятельно отвечает за сохранность своих логина и пароля от Сервиса.
      Все действия, совершенные на Сервисе с использованием принадлежащих Пользователю логина и пароля
      считаются совершенными Пользователем.

      </p><p>7. Сервис (включая информацию, получаемую с его помощью) предоставляется Ивент Линк «как есть».
      Ивент Линк не гарантирует соответствие Сервиса целям и ожиданиям Пользователей, бесперебойную и безошибочную
      работу Сервиса в целом, а также не гарантирует точность информации, получаемой с помощью Сервиса.
      Ивент Линк не несет ответственности и не возмещает никакой ущерб, прямой или косвенный, причиненный пользователю
      Сервиса или третьим лицам в результате использования или невозможности использования Сервиса ,
      а также в результате использования или невозможности использования информации, получаемой с помощью Сервиса.

      </p><p>8. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия при использовании
      Сервиса, в том числе гарантирует, что его действия соответствуют требованиям законодательства
      и не нарушают права и законные интересы третьих лиц. Пользователь самостоятельно и за свой счет обязуется
      урегулировать все претензии третьих лиц, связанные с действиями Пользователя при использовании Сервиса.

      </p><p>9. Использование Подписчиком Сервиса регулируется Регулирующими документами.

      Пользователь обязуется ознакомиться со всеми Регулирующими документами и самостоятельно несет риск такого
      неознакомления.

      </p><p>10. Подписчик несет полную личную ответственность за содержание предоставленной им информации,
      за ее соответствие нормам российского законодательства или законодательства
      места пребывания Подписчика

      </p><p>11. Подписываясь на календари Пользователей, Подписчки выражает свое безоговорочное согласие со всеми условиями
      настоящего Соглашения и обязуется их соблюдать или прекратить использование Сервиса, а также даёт согласие на
      обработку своих персональных данных.

      </p><p>12. Все данные, собираемые и хранимые Сервисом, Ивент Линк рассматривает как персональные данные и
      конфиденциальную информацию Пользователя или Подписчика. Ивент Линк обязуется, если иное не предусмотрено применимым
      законодательством, сохранять данные в тайне и не передавать такие данные третьим лицам, за исключением случаев,
      когда Пользователь или Подписчик разрешил такую передачу или самостоятельно разрешил доступ к данным для третьих лиц.

      </p><p>13. Ивент Линк не гарантирует, что использование сервиса, сбор, обработка и передача Ивент Линк информации
      не нарушает законодательство
      места пребывания Пользователя. Пользователь используетс Сервис на свой риск, и самостоятельно несет
      ответственность за все свои действия, связанные с использованием Сервиса.

      </p><p>14. Ивент Линк имеет право без уведомления по собственному усмотрению прекратить или
      приостановить доступ Пользователя или Подписчика к Сервису без объяснения причин, в частности,
      в случае нарушения Пользователем или Подписчиком требований настоящего Соглашения.

      </p><p>15. Все претензии, связанные с использованием/невозможностью использования Сервиса, должны направляться
      на электронный адрес support@evli.in.

      </p><p>16. Ивент Линк может в любое время без уведомления Пользователей и Подписчиков изменять
      текст настоящего Соглашения и/или
      любые иные условия использования Сервиса. Актуальный текст настоящего Соглашения размещается по адресу:
      https://evli.in/#/license.</p>

    </div>
  </div>
</template>

<script>

export default {
  name: 'License',
  data() {
    return {
      lang: 'en'
    }
  }
}
</script>

<style lang="scss">
body {
  padding: 20px;
}
.license_lang_link {
  cursor: pointer;
  text-decoration: underline;
}
</style>
